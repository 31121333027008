import React, { Component } from "react";
import { BrowserRouter as Router, Redirect, Switch } from "react-router-dom";
/*------ Pages-----*/
// import { Home } from "./Pages/Home";
// import DigitalMarketing from "./Pages/Digital-marketing";
// import PaymentProcessing from "./Pages/Payment-processing";
// import HRManagement from "./Pages/HR-Management";
import Startup from "./Pages/Startup";
// import HomeCRM from "./Pages/Home-CRM";
// import About from "./Pages/About";
// import Service from "./Pages/Service";
// import Process from "./Pages/Process";
import Team from "./Pages/Team";
// import Portfolio2col from "./Pages/Portfolio-2col";
// import Portfolio3col from "./Pages/Portfolio-3col";
// import Portfoliofull4col from "./Pages/Portfolio-fullwidth-4col";
// import PortfolioSingle from "./Pages/PortfolioSingle";
// import Bloglist from "./Pages/Bloglist";
// import BlogSingle from "./Pages/BlogSingle";
import Contact from "./Pages/Contact";
import ScrollToTopRoute from "./ScrollToTopRoute";
// import Landing from "./Pages/Landing";
// import homesupport from "./Pages/home-support";
// import homeERP from "./Pages/Home-ERP";
// import homeHosting from "./Pages/HomeHosting";
// import homeSecurity from "./Pages/HomeSecurity";
// import homeSoftwareDark from "./Pages/Home-software-dark";
// import HomeAppShowcase from "./Pages/Home-app-showcase";
// import HomeCloud from "./Pages/Home-cloud";
// import HomeTracking from "./Pages/Home-tracking";
// import HomeEvent from "./Pages/Home-event";
// import HomeChat from "./Pages/Home-chat";
// import Price from "./Pages/Price";
// import Faq from "./Pages/Faq";
// import ServiceDetails from "./Pages/ServiceDetails";
// import SignIn from "./Pages/SignIn";
// import SignUp from "./Pages/SignUp";
// import BlogGridPage from "./Pages/BlogGridPage";

import NotFound from "./Pages/404";
import UnderConstruction from "./Pages/Under-Construction";

class App extends Component {
  componentDidMount() {
    this.props.hideLoader();
  }
  render() {
    return (  
      <Router>
        <Switch>
          <ScrollToTopRoute exact path="/" component={Startup} />

          <ScrollToTopRoute exact path={"/Home"}>
            <Redirect to="/" />
          </ScrollToTopRoute>

          <ScrollToTopRoute exact path="/Team" component={Team} />

          <ScrollToTopRoute exact path="/Contact" component={Contact} />
          <ScrollToTopRoute
            exact
            path="/UnderConstruction"
            component={UnderConstruction}
          />

          <ScrollToTopRoute component={NotFound} />
        </Switch>
      </Router>
    );
  }
}

export default App;
