import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Sticky from "react-stickynode";
import Logo from "./Logo";

class CustomNavbar extends Component {
  render() {
    var { mClass, nClass, cClass, slogo, hbtnClass } = this.props;

    const scrollTo = (id) => {
      const element = document.getElementById(id);
      element &&
        element.scrollIntoView({
          behavior: "smooth",
        });
    };

    return (
      <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
        <header className="header_area">
          <nav className={`navbar navbar-expand-lg menu_one ${mClass}`}>
            <div className={`container ${cClass}`}>
              <Logo slogo={slogo} />
              <button
                className="navbar-toggler collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="menu_toggle">
                  <span className="hamburger">
                    <span></span>
                    <span></span>
                    <span></span>
                  </span>
                  <span className="hamburger-cross">
                    <span></span>
                    <span></span>
                  </span>
                </span>
              </button>

              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className={`navbar-nav menu ml-auto ${nClass}`}>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/Home">
                      Home
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      to="/Home#about-us"
                      onClick={(e) => {
                        scrollTo("about-us");
                      }}
                    >
                      About
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink className="nav-link" to="/Team">
                      Team
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink className="nav-link" to="/UnderConstruction">
                      Portfolio
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink
                      title="Pricing"
                      className="nav-link"
                      to="/UnderConstruction"
                    >
                      Blog
                    </NavLink>
                  </li>

                  {/* <li className="dropdown submenu nav-item">
                    <Link
                      title="Pages"
                      className="dropdown-toggle nav-link"
                      data-toggle="dropdown"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                      to="#"
                    >
                      Portfolio
                    </Link>
                    <ul role="menu" className=" dropdown-menu">
                      <li className="nav-item">
                        <NavLink
                          title="Portfolio 2"
                          className="nav-link"
                          to="/Portfolio-2col"
                        >
                          Portfolio 2col
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          title="Portfolio 3"
                          className="nav-link"
                          to="/Portfolio-3col"
                        >
                          Portfolio 3col
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          title="Portfolio Fullwidth"
                          className="nav-link"
                          to="/Portfolio-fullwidth-4col"
                        >
                          Portfolio fullwidth
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          title="PortfolioSingle"
                          className="nav-link"
                          to="/PortfolioSingle"
                        >
                          Portfolio Single
                        </NavLink>
                      </li>
                    </ul>
                  </li> */}
                </ul>
                <NavLink title="Pricing" className="nav-link" to="/Contact">
                  <div className={`btn_get btn_hover ${hbtnClass}`}>
                    Contact Us
                  </div>
                </NavLink>
              </div>
            </div>
          </nav>
        </header>
      </Sticky>
    );
  }
}

export default CustomNavbar;

//{
  /* <li className="nav-item dropdown submenu">
  
    <a
      className="nav-link dropdown-toggle"
      href=".#"
      role="button"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      Blog
    </a>
  <ul className="dropdown-menu">
    <li className="nav-item">
      <NavLink to="/Bloglist" className="nav-link">
        Blog List
      </NavLink>
    </li>
    <li className="nav-item">
      <NavLink to="/BlogGridPage" className="nav-link">
        Blog Grid
      </NavLink>
    </li>
    <li className="nav-item">
      <NavLink to="/BlogSingle" className="nav-link">
        Blog Single
      </NavLink>
    </li>
  </ul>
</li>
                </ul > */
//}
