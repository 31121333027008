import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Logo extends Component {
    render() {
        var { slogo, size } = this.props;
        return (
            <Link className={`navbar-brand ${slogo}`} to="/">
                <img
                    src={require("../dextro-illustrations/LogoDextro.svg")}
                    height={size ? size : "50rem"}
                    alt=""
                />
                <img
                    src={require("../dextro-illustrations/LogoDextro.svg")}
                    height={size ? size : "50rem"}
                    alt="logo"
                />
            </Link>
        )
    }
}

export default Logo;