
import React from "react";

import Teamitem from "../Team/Teamitem";
import TeamData from "./teamdata.json";

const Team = () => {
  return (
    <section className="experts_team_area sec_pad">
      <div className="container">
        <div className="row">
          {TeamData.team.map((obj) => {
            if (obj.name === "Zahid Ahmad Khan") {
              return (
                <div className="col-lg-3 col-sm-6" id = "zahidahmadkhan">
                  <Teamitem
                    teamImage={obj.image}
                    memberN={obj.name}
                    memberd={obj.desc}
                    socials={obj.socials}
                  />
                </div>
              );
            }
            else {
              return (
                <div className="col-lg-3 col-sm-6">
                  <Teamitem
                    teamImage={obj.image}
                    memberN={obj.name}
                    memberd={obj.desc}
                    socials={obj.socials}
                  />
                </div>
              );
            }
            
          })}
        </div>
      </div>
    </section>
  );
};
export default Team;
