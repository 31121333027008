import React from "react";
import CustomNavbar from "../components/CustomNavbar";
import FooterTwo from "../components/Footer/FooterTwo";
import FooterData from "../components/Footer/FooterData";
import { NavLink } from "react-router-dom";
const NotFound = () => (
  <div className="body_wrapper">
    <CustomNavbar hbtnClass="new_btn" />
    <section className="error_two_area">
      <div className="container flex">
        <div className="error_content_two text-center">
          <img src={require("../img/new/error.png")} alt="" />
          <h2>Error. We can’t find the page you’re looking for.</h2>
          <p>Sorry for the inconvenience...</p>
          <NavLink
            title="Pricing"
            className="nav-link"
            to="/Home"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div className={`btn_get btn_hover`}>Go Back to Home Page</div>
          </NavLink>
        </div>
      </div>
    </section>
    <FooterTwo FooterData={FooterData} />
  </div>
);
export default NotFound;
