import React, { Component } from "react";
import AboutUs from "../About/AboutUs";
import Featuresitems from "./Featuresitems";

class Features extends Component {
  render() {
    var { aClass } = this.props;
    return (
      <section className={`bg_color ${aClass}`}>
        <div className="container">
          <AboutUs />

          <br />
          <h2
            className="f_size_30 f_600 t_color3 l_height40 text-center wow fadeInUp"
            data-wow-delay="0.3s"
          >
            Our Expertise Lies In Providing You With...
          </h2>
          <div className="features_info">
            <Featuresitems
              rowClass="row flex-row-reverse"
              aClass="pr_70 pl_70"
              fimage="web_app.svg"
              iImg="icon01.png"
              ftitle="Full Stack Web Development Services"
              descriptions="We develop full stack applications suited especially to your needs and use cases. We specialize in providing a responsive and an interactive frontend paired with robust and optimized business logic."
            />
            <Featuresitems
              rowClass="row agency_featured_item_two"
              aClass="pl_100"
              fimage="mobile_app.svg"
              iImg="icon02.png"
              ftitle="Multi-Platform Mobile Applications"
              descriptions="We provide modern UI and functionality which is simultaneously compatible with several platforms including iOS and Android."
            />
            <Featuresitems
              rowClass="row flex-row-reverse"
              aClass="pr_70 pl_70"
              fimage="blockchain.svg"
              iImg="icon3.png"
              ftitle="Blockchain Integration & DApp Development"
              descriptions="Complete decentralized application development and custom integrations for your existing solutions.keep yourself upto date with the latest revolution in the world of technology."
            />

            <Featuresitems
              rowClass="row agency_featured_item_two"
              aClass="pr_70 pl_70"
              fimage="pen_testing.svg"
              iImg="icon_04.png"
              ftitle="Penetration Tests & Web Security Services"
              descriptions="Secure your systems and solutions from malicious actors and the most prevalent active threats."
            />
            <Featuresitems
              rowClass="row flex-row-reverse"
              aClass="pl_100"
              fimage="automation.svg"
              iImg="icon_05.png"
              ftitle="WorkFlow Automation  & Data Extraction"
              descriptions="Automate your tasks on the web and scrap large amount of data with our speedy scripts."
            />
          </div>
        </div>
      </section>
    );
  }
}
export default Features;
