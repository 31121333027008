import React, { Component } from "react";
//import { Link } from 'react-router-dom';
import Reveal from "react-reveal/Reveal";
import LinkWidget from "./FooterWidget/LinkWidget";
import Logo from "../Logo";
import data from "../../data.json"
class Footer extends Component {
  
  

  render() {
    let FooterData = this.props.FooterData;
    var mailto = "mailto:"+ data.email;
    var callus = "tel:" + data.phonenumber;

    return (
      <footer className="new_footer_area bg_color">
        <div className="new_footer_top">
          <div className="container">
            <div className="row">
              {FooterData.CompanyWidget.map((widget) => {
                return (
                  <Reveal effect="fadeInUp" key={widget.id}>
                    <div className="col-lg-3 col-md-6">
                      <div
                        className="f_widget company_widget wow fadeInLeft"
                        data-wow-delay="0.2s"
                      >
                        <Logo slogo="sticky-logo" />
                        <div className="widget-wrap">
                          <p className="f_400 f_p f_size_15 mb-0 l_height34">
                            <span>Email:</span>{" "}
                            <a href={mailto} className="f_400">
                              {data.email}
                            </a>
                          </p>
                          <p className="f_400 f_p f_size_15 mb-0 l_height34">
                            <span>Phone:</span>{" "}
                            <a href={callus} className="f_400">
                              {data.phonenumber}
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </Reveal>
                );
              })}
              <LinkWidget />
              <LinkWidget />
              <LinkWidget
                title="About Us"
                FooterData={FooterData.about}
              ></LinkWidget>
            </div>
          </div>
          <div className="footer_bg">
            <div className="footer_bg_one"></div>
            <div className="footer_bg_two"></div>
          </div>
        </div>
      </footer>
    );
  }
}
export default Footer;
