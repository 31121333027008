import React, { Component } from "react";

class AboutUs extends Component {
    render() {
        return (
            <>
                <br id="about-us" />
                <br /><br /><br /><br /><br />
                <h2
                    className="f_size_40 f_600 t_color3 l_height40 text-center wow fadeInUp"
                    data-wow-delay="0.3s"
                >
                    About Us
                </h2>
                <br />
                <p className="text-center f_size_18">
                    We are a team of highly-functioning developers whose strengths lies in using state-of-the-art tools and technologies, communicating effectively and delivering end-products efficiently by following the industry practices.
                </p>
                <br /><br />
            </>
        )
    }
}

export default AboutUs