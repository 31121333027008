import React, { Component } from 'react';
import Reveal from 'react-reveal/Reveal';
import { NavLink } from 'react-router-dom';
class LinkWidget extends Component {
    render() {
        let FooterData = this.props.FooterData;
        var title = this.props.title ? this.props.title : "";
        return (
            <Reveal effect="fadeInUp" duration={1200}>
                <div className="col-lg-3 col-md-6">
                    <div className="f_widget about-widget pl_70 wow fadeInLeft" data-wow-delay="0.4s">
                        <h3 className="f-title f_600 t_color f_size_18 mb_40">{title}</h3>
                        <ul className="list-unstyled f_list">
                            {FooterData ?
                                FooterData.map(item => {
                                    return (
                                        <li key={item.id}>
                                            <a href={item.url}>
                                                {item.route ?
                                                    <NavLink title={item.text} key={item.id} to={item.route}>{item.text}</NavLink> :
                                                    item.text
                                                }
                                            </a>
                                        </li>
                                    )
                                }) : null
                            }
                        </ul>
                    </div>
                </div>
            </Reveal>
        )
    }
}

export default LinkWidget;