import React from "react";
import CustomNavbar from "../components/CustomNavbar";
import FooterTwo from "../components/Footer/FooterTwo";
import { NavLink } from "react-router-dom";
import FooterData from "../components/Footer/FooterData";
const UnderConstruction = () => (
  <div className="body_wrapper">
    <CustomNavbar hbtnClass="new_btn" />
    <section className="error_two_area">
      <div className="container flex">
        <div className="error_content_two text-center">
          <img
            src={require("../dextro-illustrations/under_construction.svg")}
            height="300rem"
            alt=""
          />
          <h2>This Page is Under Development.</h2>
          <p>Sorry for the inconvenience...</p>
        </div>
        <NavLink
          title="Go Back Home"
          className="nav-link"
          to="/"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className={`btn_get btn_hover`}>Go Back to Home Page</div>
        </NavLink>
      </div>
    </section>

    <FooterTwo FooterData={FooterData} />
  </div>
);
export default UnderConstruction;
