import React, { Component } from "react";
import Slider from "react-slick";

class Pserviceslider extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 2000,
      slidesToShow: 3,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: "100px",
      responsive: [
        {
          breakpoint: 1250,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: "100px",
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            centerMode: false,
            centerPadding: "0px",
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
            centerPadding: "0px",
          },
        },
      ],
    };
    return (
      <Slider className="service_carousel" {...settings}>
        <div className="service_item">
          <h4
            className="f_600 f_size_20 t_color2 mb_20"
            style={{ "text-align": "center" }}
          >
            React JS
          </h4>
          <img
            src={require("../../../dextro-illustrations/AssetReact.svg")}
            alt="react-js-logo"
          />
        </div>

        <div className="service_item">
          <h4
            className="f_600 f_size_20 t_color2 mb_20"
            style={{ "text-align": "center" }}
          >
            Flutter
          </h4>
          <img
            src={require("../../../dextro-illustrations/AssetFlutter.svg")}
            alt="flutter-logo"
          />
        </div>

        <div className="service_item">
          <h4
            className="f_600 f_size_20 t_color2 mb_20"
            style={{ "text-align": "center" }}
          >
            C Sharp
          </h4>
          <img
            src={require("../../../dextro-illustrations/Assetcsharp.svg")}
            alt="c-sharp-logo"
          />
        </div>

        <div className="service_item">
          <h4
            className="f_600 f_size_20 t_color2 mb_20"
            style={{ "text-align": "center" }}
          >
            Django
          </h4>
          <img
            src={require("../../../dextro-illustrations/Assetdjango.svg")}
            alt="python-django-logo"
          />
        </div>

        <div className="service_item">
          <h4
            className="f_600 f_size_20 t_color2 mb_20"
            style={{ "text-align": "center" }}
          >
            GoLang
          </h4>
          <img
            src={require("../../../dextro-illustrations/AssetGo.svg")}
            alt="go-lang-logo"
          />
        </div>

        <div className="service_item">
          <h4
            className="f_600 f_size_20 t_color2 mb_20"
            style={{ "text-align": "center" }}
          >
            Dotnet
          </h4>
          <img
            src={require("../../../dextro-illustrations/AssetNET.svg")}
            alt="dot-net-logo"
          />
        </div>

        <div className="service_item">
          <h4
            className="f_600 f_size_20 t_color2 mb_20"
            style={{ "text-align": "center" }}
          >
            Python
          </h4>
          <img
            src={require("../../../dextro-illustrations/Assetpython.svg")}
            alt="python-logo"
          />
          
        </div>

        <div className="service_item">
          
          <h4
            className="f_600 f_size_20 t_color2 mb_20"
            style={{ "text-align": "center" }}
          >
            Solidity
          </h4>
          
          <img
            src={require("../../../dextro-illustrations/Assetsolidity.svg")}
            alt="python-logo"
          />
          
        </div>



      </Slider>
    );
  }
}
export default Pserviceslider;
