import React from "react";
import CustomNavbar from "../components/CustomNavbar";
import StartupBanner from "../components/Banner/StartupBanner";
// import Sservice from "../components/Service/Sservice/Sservice";
// import AgencyAbout from "../components/About/AgencyAbout";
import Features from "../components/Features/Features";
// import Funfact from "../components/Funfact";
// import Partner from "../components/Partner";
// import MarketingTestimonial from "../components/Testimonial/MarketingTestimonial";
// import ServiceData from "../components/Service/ServiceData";
// import BannerData from "../components/Banner/BannerData";
// import FooterTwo from "../components/Footer/FooterTwo";
import FooterData from "../components/Footer/FooterData";
import Pserviceslider from "../components/Service/Sservice/Pserviceslider";
import Sectitle from "../components/Title/Sectitle";
import Footer from "../components/Footer/Footer";
// import AboutUs from "../components/About/AboutUs";

const Startup = () => (
  <div className="body_wrapper">
    <CustomNavbar
      slogo="sticky_logo"
      mClass="menu_four"
      nClass="w_menu ml-auto mr-auto"
    />
    <StartupBanner />

    <Features aClass="agency_featured_area_two" />

    <div className="container custom_container" style={{ marginTop: 50 }}>
      <Sectitle
        Title="Our Technology Stack Includes"
        TitleP=""
        tClass="t_color3"
        sClass="sec_title text-center mb_70"
      />
      <Pserviceslider />
    </div>
    <Footer FooterData={FooterData} />
  </div>
);
export default Startup;
